import React from 'react'
import { graphql } from "gatsby"
import { Link } from 'gatsby'
import moment from 'moment'
import _ from 'lodash'

import { Col, Container, Row } from 'react-bootstrap'
import Layout from "../components/layout"
import SEO from "../components/seo"
import homapagespotlight from "../media/img/home/homepage-spotlight-1.jpg"

import BorderLayout from '../components/BorderLayout'
import { ParallaxProvider } from 'react-scroll-parallax'
import ParallaxIcon from '../components/ParallaxIcon'

import parallaxIcon1 from '../media/img/home/icons/icon-1.jpg'
import parallaxIcon2 from '../media/img/home/icons/icon-2.jpg'
import parallaxIcon3 from '../media/img/home/icons/icon-3.jpg'
import parallaxIcon4 from '../media/img/home/icons/icon-4.jpg'
import parallaxIcon5 from '../media/img/home/icons/icon-5.jpg'
import parallaxIcon6 from '../media/img/home/icons/icon-6.jpg'
import parallaxIcon7 from '../media/img/home/icons/icon-7.jpg'
import galleryThumb from "../media/img/news/gallerythumb.png"
import Wiedenism from '../components/Wiedenism'


export default function Index(props) {
    let allEmployees = []
    props.data.allIntranetEmployees.edges.map(employee => {
        allEmployees.push({
            "name": employee.node.name,
            "dob": employee.node.dob,
            "doj": employee.node.doj,
            "image": employee.node.bday_image,
            "annimage": employee.node.anniversary_image,
            "copy":employee.node.bday_copy,
            "anniversaryCopy":employee.node.anniversary_copy
        })
        return allEmployees
    })
    let upcomingBirthdays = [], upcomingWorkAnniversaries = []
    let hasBirthdayToday = false, hasAnniversaryToday = false
    function daysUntil(date) {
        let birthday = moment(date)
        let today = moment().format("YYYY-MM-DD")
        let age = moment(today).diff(birthday, 'years')
        moment(age).format("YYYY-MM-DD")
        
        let nextBirthday = moment(birthday).add(age, 'years')
        moment(nextBirthday).format("YYYY-MM-DD")
        
        if (nextBirthday.isSame(today)) {
            hasBirthdayToday = true
            return ({
                "days":0,
            });
            } else {
            nextBirthday = moment(birthday).add(age + 1, 'years');
            return ({
                "days":nextBirthday.diff(today, 'days'),
            })
        }
    }
    function daysUntilAnniversary(date) {
        let anniversary = moment(date)
        let today = moment().format("YYYY-MM-DD")
        let age = moment(today).diff(anniversary, 'years')
        moment(age).format("YYYY-MM-DD")
        
        let nextAnniversary = moment(anniversary).add(age, 'years')
        moment(nextAnniversary).format("YYYY-MM-DD")
        
        if (nextAnniversary.isSame(today)) {
        hasAnniversaryToday = true
        return ({
            "days":0,
            "years":age-1,
        });
        } else {
        nextAnniversary = moment(anniversary).add(age + 1, 'years');
        return ({
            "days":nextAnniversary.diff(today, 'days'),
            "years":age,
        })
        }
    }
    allEmployees.map(employee => {
        //check birthday and create new array
        let checkBirthday = daysUntil(employee.dob)
        if(hasBirthdayToday && checkBirthday.days === 0){
            upcomingBirthdays.push({
                "name": employee.name,
                "dob": employee.dob,
                "doj": employee.doj,
                "image": employee.image,
                "days_remaining": checkBirthday.days,
                "copy":employee.copy
            })
        }
        //check Anniversary and create new array
        let checkAnniversary = daysUntilAnniversary(employee.doj)
        if(hasAnniversaryToday && checkAnniversary.days === 0){
            upcomingWorkAnniversaries.push({
                "name": employee.name,
                "dob": employee.dob,
                "doj": employee.doj,
                "image": employee.annimage,
                "days_remaining": checkAnniversary.days,
                "total_years": checkAnniversary.years, 
                "anniversaryCopy":employee.anniversaryCopy
            })
        }
        return true
    })
    let sortByLatestBirthday = _.sortBy(upcomingBirthdays, 'days_remaining')
    let sortByLatestAnniversary = _.sortBy(upcomingWorkAnniversaries, 'days_remaining')
    let sortByDoj = _.sortBy(upcomingWorkAnniversaries, 'doj')
    // console.log(sortByDoj[0].total_years)
    return (
        <Layout pageInfo={{ pageName: "Home" }}>
            <SEO title="Home" />
            <div className="home">
                <div className="home__Spotlight" style={{backgroundColor:"#36d5d4"}}>
                    <img src={homapagespotlight} className="image" alt="" />
                    {/* <div className="wrap">
                        <h1>Welcome to <span>Wieden + Kennedy</span> Delhi</h1>
                    </div> */}
                    <div className="home__Spotlight__Links">
                        <ul>
                            <li><Link to="/who-we-are">Who We Are</Link></li>
                            <li><Link to="/the-cheat-sheet">The Cheat Sheet</Link></li>
                            <li><Link to="/learn-and-grow">Learn & Grow</Link></li>
                            <li><Link to="/other-essentials">Other Essentials</Link></li>
                            <li><Link to="/whats-happening">What's Happening</Link></li>
                        </ul>
                    </div>
                </div>
                {sortByLatestBirthday.length > 0 && sortByLatestAnniversary.length > 0 && sortByDoj[0].total_years > 1 &&(
                <Wiedenism />
                )}
                <div className="home__alternate__blocks">
                    <ParallaxProvider>
                        <Container>
                            {sortByLatestBirthday.length > 0 && (
                                <Row>
                                    <ParallaxIcon src={parallaxIcon1} left={45} top={-5} parallax={[100,-100]} />
                                    <ParallaxIcon src={parallaxIcon2} left={100} top={100} parallax={[300,-300]} />
                                    <Col className="image text-center">
                                        <BorderLayout color={`#8163E7`}>
                                            <img src={sortByLatestBirthday[0].image === `` ? galleryThumb : sortByLatestBirthday[0].image } alt="" />
                                        </BorderLayout>
                                    </Col>
                                    <Col className="d-flex align-items-center mobile-margin">
                                        <div className="copy">
                                            <h3>{hasBirthdayToday ? `Happy` : `Upcoming`} birthday {sortByLatestBirthday[0].name}!</h3>
                                            <p>{sortByLatestBirthday[0].copy === `` ? `Happy Birthday` : sortByLatestBirthday[0].copy }</p>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            {sortByLatestAnniversary.length > 0 && sortByDoj[0].total_years > 1 && (
                                <Row>
                                    <ParallaxIcon src={parallaxIcon3} left={-5} top={80} parallax={[300,-300]} />
                                    <ParallaxIcon src={parallaxIcon4} left={45} top={40} parallax={[150,-150]} />
                                    <ParallaxIcon src={parallaxIcon5} left={90} top={90} parallax={[200,-200]} />
                                    <Col className="image">
                                        <BorderLayout color={`#74C8F2`}>
                                            <img src={sortByLatestAnniversary[0].image === `` ? galleryThumb : sortByLatestAnniversary[0].image } alt=""></img>
                                        </BorderLayout>
                                    </Col>
                                    <Col className="d-flex `align-items-center mobile-margin">
                                        <div className="copy">
                                            <h3>{hasAnniversaryToday ? `Happy` : `Upcoming`} Anniversary {sortByLatestAnniversary[0].name}</h3>
                                            <p>{sortByLatestAnniversary[0].anniversaryCopy === `` ? `` : sortByLatestAnniversary[0].anniversaryCopy }</p>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            {props.data.somethingSomething.edges.map(somethingArticle => {
                                return(
                                    <Row key={somethingArticle.node.id}>
                                    <ParallaxIcon src={parallaxIcon6} left={100} top={30} parallax={[180,-180]} />
                                        {somethingArticle.node.type === "internal" && (
                                            <React.Fragment key={somethingArticle.node.id}>
                                            <Col className="image">
                                                <BorderLayout color={`#224DE7`}>
                                                    <img src={somethingArticle.node.image} alt="" />
                                                </BorderLayout>
                                            </Col>
                                            <Col className="d-flex align-items-center mobile-margin">
                                                <div className="copy">
                                                    <h3>{somethingArticle.node.title}</h3>
                                                    <p dangerouslySetInnerHTML={{ __html: somethingArticle.node.excerpt}} />
                                                    <small><Link to={`/library/${somethingArticle.node.id__normalized}`}>Know More</Link></small>
                                                </div>
                                            </Col>
                                        </React.Fragment>
                                        )}
                                        {somethingArticle.node.type === "external" && (
                                            <React.Fragment key={somethingArticle.node.id}>
                                            <Col className="image">
                                                <BorderLayout color={`#224DE7`}>
                                                    <img src={somethingArticle.node.image} alt="" />
                                                </BorderLayout>
                                            </Col>
                                            <Col className="d-flex align-items-center mobile-margin">
                                                <div className="copy">
                                                    <h3>{somethingArticle.node.title}</h3>
                                                    <p dangerouslySetInnerHTML={{ __html: somethingArticle.node.excerpt}} />
                                                    <small><a target="_blank" rel="noreferrer" href={somethingArticle.node.link}>Know More</a></small>
                                                </div>
                                            </Col>
                                        </React.Fragment>
                                        )}
                                    </Row>
                                )
                            })
                            }
                            {props.data.work.edges.map(workArticle => {
                                    return(
                                        <Row>
                                        <ParallaxIcon src={parallaxIcon7} left={45} top={40} parallax={[150,-150]} />
                                        <React.Fragment key={workArticle.node.id}>
                                        {workArticle.node.type === 'internal' && (
                                            <React.Fragment key={workArticle.node.id}>
                                                <Col className="image">
                                                    <BorderLayout color={`#CBDD39`}>
                                                        <img src={workArticle.node.image} alt="" />
                                                    </BorderLayout>
                                                </Col>
                                                <Col className="d-flex align-items-center mobile-margin">
                                                    <div className="copy">
                                                        <h3>{workArticle.node.title}</h3>
                                                        <p dangerouslySetInnerHTML={{ __html: workArticle.node.excerpt}} />
                                                        <small><Link to={`/work/${workArticle.node.id__normalized}`}>Know More</Link></small>
                                                    </div>
                                                </Col>
                                            </React.Fragment>
                                        )}
                                        {workArticle.node.type === 'external' && (
                                            <React.Fragment key={workArticle.node.id}>
                                                <Col className="image">
                                                    <BorderLayout color={`#CBDD39`}>
                                                        <img src={workArticle.node.image} alt="" />
                                                    </BorderLayout>
                                                </Col>
                                                <Col className="d-flex align-items-center mobile-margin">
                                                    <div className="copy">
                                                        <h3>{workArticle.node.title}</h3>
                                                        <p dangerouslySetInnerHTML={{ __html: workArticle.node.excerpt}} />
                                                        <small><a target="_blank" rel="noreferrer" href={workArticle.node.link}>Know More</a></small>
                                                    </div>
                                                </Col>
                                            </React.Fragment>
                                        )}
                                        </React.Fragment>
                                        </Row>
                                    )
                                })
                            }
                            
                        </Container>
                    </ParallaxProvider>
                </div>
                <Wiedenism />
                {/* <div className="home__relative__links">
                    <Container>
                        <Row className="d-flex">
                            <Col>
                                <BorderLayout color={`#A8233D`}>
                                    <div className="links__Wrap">
                                        <RelatedArticleSingle title={`Who We Are`} slug={`/who-we-are`} />
                                    </div>
                                </BorderLayout>
                            </Col>
                            <Col>
                                <BorderLayout color={`#30A2DE`}>
                                    <div className="links__Wrap">
                                        <RelatedArticleSingle title={`The Cheat Sheet`} slug={`/the-cheat-sheet`} />
                                    </div>
                                </BorderLayout>
                            </Col>
                            <Col>
                                <BorderLayout color={`#FFB100`}>
                                    <div className="links__Wrap">
                                        <RelatedArticleSingle title={`Learn & Grow`} slug={`/learn-and-grow`} />
                                    </div>
                                </BorderLayout>
                            </Col>
                            <Col>
                                <BorderLayout color={`#8163E7`}>
                                    <div className="links__Wrap">
                                        <RelatedArticleSingle title={`Other Essentials`} slug={`/other-essentials`} />
                                    </div>
                                </BorderLayout>
                            </Col>
                        </Row>
                    </Container>
                </div> */}
            </div>
        </Layout>
    )
}
export const query = graphql`
    query PostHome {
        allIntranetEmployees {
            edges {
            node {
                id
                name
                image
                doj
                dob
                email
                bday_image
                bday_copy
                anniversary_copy
                anniversary_image
                id__normalized
            }
            }
        }
        somethingSomething: allIntranetPost(limit: 1, filter: {featured: {eq: 1}, category: {eq: "Something + Something"}}) {
            edges {
                node {
                    id
                    id__normalized
                    title
                    image
                    type
                    link
                    excerpt
                }
            }
        }
        work: allIntranetPost(limit: 1, filter: {featured: {eq: 1}, category: {eq: "Work"}}) {
            edges {
                node {
                    id
                    id__normalized
                    title
                    image
                    type
                    link
                    excerpt
                }
            }
        }
    }
`